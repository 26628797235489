<template>
    <div class="video-container">
        <div class="photo_count">{{ photoCount }} of 7</div>
        <video ref="video" class="custom-video" autoplay playsinline></video>
        <canvas ref="canvas" width="2048" height="1556" style="display: none;"></canvas>
        <button @click="capturePhoto" class="capture-button">
            <span class="capture-icon"></span>
        </button>
        <button class="btn btn-danger btn-save" @click="close">X</button>
        <div class="loadingIndicator">
            <LoadingIcon
                :active="loadingIconActive"
                :message="'Uploading Photo'"
                :msgClassList="'text-primary'"
            />
        </div>
    </div>
</template>

<script>
import {store} from "@/store/BuildScheduler.store";
import LoadingIcon from "@/components/utils/LoadingIcon.vue";

export default {
    components: {LoadingIcon},
    data() {
        return {
            mediaStream: null,
            selectedCamera: null,
            videoDevices: [],
            capturedImage: null,
            photoCount: 0,
            loadingIconActive: false
        }
    },
    async mounted() {
        this.startCamera();
        window.addEventListener('resize', this.onResize);
    },
    props: {
        file_type_id: {
            default: 0
        },
        cdi_reference_id: {
            default: 0
        },
        cdi_reference2_id: {
            default: 0
        },
    },
    methods: {
        async startCamera() {
            this.$refs.video.addEventListener('loadedmetadata', this.setCanvas);
            this.mediaStream = await navigator.mediaDevices.getUserMedia({
                audio: false, video: {
                    facingMode: "environment", width: {ideal: 4000},
                    height: {ideal: 4000}
                }
            });
            this.$refs.video.srcObject = this.mediaStream;
            this.$refs.video.addEventListener('loadedmetadata', this.onVideoLoaded);
            this.$refs.video.play();

        },
        setCanvas() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        },
        async capturePhoto() {
            this.loadingIconActive = true;
            this.setCanvas();
            const canvas = this.$refs.canvas;
            const context = canvas.getContext('2d');
            context.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
            this.capturedImage = canvas.toDataURL('image/jpeg');
            let scope = this;

            let options = {
                'params[photo_data]': this.capturedImage,
                'params[type]': this.file_type_id,
                'params[cdi_reference_id]': this.cdi_reference_id,
                'params[cdi_reference2_id]': this.cdi_reference2_id
            };


            store.api('upload_tracker_photo', options).then(function(res) {
                if (res.opt === 'save') {
                    scope.photoCount++;
                    scope.loadingIconActive = false;
                } else {
                    scope.close();
                    scope.$appStore.errorBox(res.msg);
                }
            });
        },
        onVideoLoaded() {
            this.adjustVideoAndCanvasSizes();
        },
        onResize() {
            this.adjustVideoAndCanvasSizes();
        },
        adjustVideoAndCanvasSizes() {
            const video = this.$refs.video;
            const canvas = this.$refs.canvas;

            // Set the canvas dimensions based on the video's actual dimensions
            const videoWidth = video.videoWidth;
            const videoHeight = video.videoHeight;

            // Maintain the aspect ratio of the video
            const aspectRatio = videoWidth / videoHeight;
            if (window.innerWidth / window.innerHeight > aspectRatio) {
                video.style.width = 'auto';
                video.style.height = '100%';
            } else {
                video.style.width = '100%';
                video.style.height = 'auto';
            }

            canvas.width = videoWidth;
            canvas.height = videoHeight;
        },
        close() {
            this.loadingIconActive = false;
            if (this.mediaStream) {
                this.mediaStream.getTracks().forEach(track => track.stop());
                this.mediaStream = null;
            }
            this.$emit('closePhotoComponent', this.photoCount);
        },
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        this.close();
    },
};
</script>

<style>
@media (max-height: 500px) {
    .video-container{
        top: 20%!important;
    }
    .capture-button{
        bottom: 30%!important;
    }
}

@media(min-width: 1200px){
    .btn-save{
       bottom: 25%!important;
    }
    .photo_count{
        bottom: 20%!important;
    }
}

.btn-save{
    position: absolute;
    left: 25%;
    bottom: 15%!important;
    margin-top: 0!important;
    width: 50%;
}
.video-container {
    max-width: 150%!important;
    justify-content: center;
    align-items: center;
    z-index: 10000!important;
    text-align: center;
    width: 80%!important;
    height: 50%!important;
    position: fixed;
    left: 10%;
    top:30%;
    background-color: black;
}
.custom-video {
    max-width: 100% !important;
    max-height: 95% !important;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    margin: 0 !important;
}
.capture-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
    bottom: 30%!important;
}
.capture-icon {
    display: block;
    width: 10vh;
    height: 10vh;
    border-radius: 50%;
    background-color: #ffffff; /* Button color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 7); /* Button shadow */
}
.capture-icon:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8vh;
    height: 8vh;
    background-color: #ffffff;
    border-radius: 50%;
    border: #333 solid 2px;
}
.photo_count {
    color: #ffffff;
    font-size: x-large;
    z-index: 4000 !important;
    position: absolute;
    left: 25%;
    width: 50%;
    text-align: center!important;
    bottom: 7%;
}
video, canvas {
    width: 100%;
    height: auto;
}
.loadingIndicator {
    position: absolute;
    right: 5px;
    top: 150px;
}

</style>

