<template>
    <div v-if="loggedIn">
        <div>
            <Nav />
            <main class="col-lg-10 col-md-9 col-sm-9 left_gutter fill">
                <h1>Drawing Scheduler</h1>
                <Container :requests="drawScheduleState.data" />
            </main>
        </div>
    </div>
    <div v-else-if="!loggedIn">
        <div>
            <SignInPage></SignInPage>
        </div>
    </div>
</template>

<script>
    import Nav from '@/components/schedulers/drawsch/Nav';
    import Container from '@/components/schedulers/drawsch/Container';
    import { store } from '@/store/Tool.store';
    import { drawScheduleStore } from '@/store/DrawScheduler.store.js';
    import SignInPage from "@/views/SignInPage";

    export default {
        name: "DrawingSchedulerPage",
        components: {
            Nav,
            Container,
            SignInPage
        },
        data() {
            return {
                state: store.state,
                drawScheduleState: drawScheduleStore.state,
                startCountdown: false,
                appState: this.$appStore.state
            }
        },
        computed: {
            loggedIn: function() {
                return this.appState.loggedIn
            }
        }
    }
</script>

<style scoped>

</style>
